import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import { makeStyles } from '@mui/styles';
import dayjs from 'dayjs';
import SimpleDialog from '../../../../Common/SimpleDialog';
import { formatHandleError, numberToRealWithPeriod } from '../../../../../helpers/formatData';
import CustomDatePicker from '../../../../CustomDatePicker';

const useStyles = makeStyles((theme) => ({
  redText: {
    color: theme.palette.error.main,
  },
}));

function ProfitabilityButton({
  customer,
  setSnackbar,
  totalSelected,
  setTotalSelected,
  totalCost,
  setCostTotal,
  profitability,
  setProfitability,
  profitabilityPercentage,
  setProfitabilityPercentage,
  setLoadingProfitability,
}) {
  const classes = useStyles();

  const [startDate, setStartDate] = useState(dayjs().startOf('month'));
  const [endDate, setEndDate] = useState(dayjs().endOf('day'));

  const [orderQuantity, setOrderQuantity] = useState(0);
  const [normalTotal, setNormalTotal] = useState(0);

  const [discountTotal, setDiscountTotal] = useState(0);
  const [totalFinancialCost, setTotalFinancialCost] = useState(0);

  const [openDialogProfitability, setOpenDialogProfitability] = useState(false);
  const handleOpenDialogProfitability = () => setOpenDialogProfitability(true);

  const handleCloseDialogProfitability = () => {
    setStartDate(dayjs().startOf('month'));
    setEndDate(dayjs().endOf('day'));
    setOpenDialogProfitability(false);
  };

  const handleChangeStartDate = (date) => {
    setStartDate(date);

    if (dayjs(date).isAfter(dayjs(endDate), 'day')) {
      setEndDate(dayjs(date).add(1, 'day').endOf('day'));
    }
  };

  const handleChangeEndDate = (date) => {
    setEndDate(date);

    if (dayjs(date).isBefore(dayjs(startDate), 'day')) {
      setStartDate(dayjs(date).subtract(1, 'day').endOf('day'));
    }
  };

  useEffect(() => {
    const fetchUserOrders = async (document) => {
      try {
        setLoadingProfitability(true);

        const response = await Axios.get('/orders/profitability', {
          params: {
            startDate,
            endDate,
            document,
          },
        });

        const {
          orderQuantityCount,
          normalTotalItems,
          totalDiscount,
          itemsTotalSelected,
          costOfSaleTotalItems,
          financialCostAccumulator,
          profitTotalItems,
          receivedValueTotalItems,
        } = response.data;

        setOrderQuantity(orderQuantityCount);
        setNormalTotal(normalTotalItems);

        setDiscountTotal(totalDiscount);
        setTotalSelected(itemsTotalSelected);

        setCostTotal(costOfSaleTotalItems);
        setTotalFinancialCost(financialCostAccumulator);

        setProfitability(profitTotalItems);

        setProfitabilityPercentage(
          Math.round((1 - (1 - profitTotalItems / receivedValueTotalItems)) * 100) || 0,
        );

        setLoadingProfitability(false);
      } catch (error) {
        setLoadingProfitability(false);
        formatHandleError({
          setSnackbar,
          defaultMessage: 'Algum erro ocorreu ao buscar pedidos do usuário',
          error,
        });
      }
    };

    const document =
      customer.documents.pessoa === 'PF' ? customer.documents.cpf : customer.documents.cnpj;

    if (document) {
      fetchUserOrders(document);
    }
  }, [customer.documents.cpf, customer.documents.cnpj, startDate, endDate]);

  return (
    <Box>
      <Tooltip title={<Typography align="center">Rentabilidade que este cliente gerou</Typography>}>
        <IconButton onClick={handleOpenDialogProfitability} size="small">
          <AccountBalanceWalletOutlinedIcon />
        </IconButton>
      </Tooltip>
      {openDialogProfitability ? (
        <SimpleDialog
          openDialog={openDialogProfitability}
          handleClose={handleCloseDialogProfitability}
          dialogTitle="Rentabilidade que este cliente gerou"
          dialogText="Pedidos cancelados, devolvidos, não entregues e orçamentos não serão contabilizados."
          content={
            <Box width={600}>
              <Grid container spacing={2} justifyContent="space-between">
                <Grid item>
                  <Grid container spacing={2}>
                    <Grid item>
                      <Box width={200}>
                        <CustomDatePicker
                          size="small"
                          label="A partir de"
                          format="DD/MM/YYYY"
                          value={startDate}
                          onChange={handleChangeStartDate}
                        />
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box width={200}>
                        <CustomDatePicker
                          size="small"
                          label="Até"
                          format="DD/MM/YYYY"
                          value={endDate}
                          onChange={handleChangeEndDate}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="column">
                    <Grid item>
                      <Grid container spacing={1} alignItems="baseline">
                        <Grid item>
                          <Typography>Quantidade de pedidos:</Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="h3">{orderQuantity}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container spacing={1} alignItems="baseline">
                        <Grid item>
                          <Typography>Desconto total:</Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="h3" className={classes.redText}>
                            R$ {numberToRealWithPeriod(discountTotal)} (
                            {Math.round((1 - (1 - discountTotal / normalTotal)) * 100) || 0}
                            %)
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container spacing={1} alignItems="baseline">
                        <Grid item>
                          <Typography>Preço de venda total:</Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="h3">
                            R$ {numberToRealWithPeriod(totalSelected)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="column">
                    <Grid item>
                      <Grid container spacing={1} alignItems="baseline">
                        <Grid item>
                          <Typography>Custo de venda total:</Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="h3" className={classes.redText}>
                            R$ {numberToRealWithPeriod(totalCost)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container spacing={1} alignItems="baseline">
                        <Grid item>
                          <Typography>Total de taxas:</Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="h3" className={classes.redText}>
                            R$ {numberToRealWithPeriod(totalFinancialCost)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container spacing={1} alignItems="baseline">
                        <Grid item>
                          <Typography>Rentabilidade total:</Typography>
                        </Grid>
                        <Grid item>
                          <Typography
                            variant="h3"
                            className={profitability < 0 ? classes.redText : null}
                          >
                            R$ {numberToRealWithPeriod(profitability)} ({profitabilityPercentage}
                            %)
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          }
        />
      ) : null}
    </Box>
  );
}

export default ProfitabilityButton;
