import {
  Alert,
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import React, { useState, useEffect, useCallback } from 'react';
import Axios from 'axios';
import FabGroupButtons from '../../Common/FabGroupButtons';
import SimpleBackdrop from '../../Common/SimpleBackdrop';
import SimpleDialog from '../../Common/SimpleDialog';
import searchTemplatesEmails from '../../../services/melhorGestao/templatesEmails';
import { base64ToHtml, formatHandleError } from '../../../helpers/formatData';
import CustomPagination from '../../Common/CustomPagination';

const useStyles = makeStyles(() => ({
  delete: {
    marginTop: 5,
  },
  deleteTableHead: {
    marginTop: 5,
  },
}));

function TemplatesTable({
  fabButtons,
  handleOpenUsersTable,
  handleOpenEmailDialog,
  setFieldsTemplate,
  snackbar,
  setSnackbar,
  handleCloseSnackbar,
}) {
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [limit] = useState(20);
  const [templatesEmailsList, setTemplatesEmailsList] = useState([]);
  const [loadingTemplatesEmailsList, setLoadingTemplatesEmailsList] = useState(false);
  const [totalTemplatesEmails, setTotalTemplatesEmails] = useState(0);
  const [selectTemplateToDelete, setSelectTemplateToDelete] = useState();

  const getTemplates = async () => {
    searchTemplatesEmails(
      {
        limit,
        skip: page * limit - limit,
        setLoadingTemplatesEmailsList,
        setTemplatesEmailsList,
        setTotalTemplatesEmails,
      },
      [page, limit],
    );
  };

  useEffect(() => {
    getTemplates();
  }, [totalTemplatesEmails]);

  const handleChangePage = useCallback(
    (event, value) => {
      setPage(value);
    },
    [limit, page, templatesEmailsList],
  );

  const handleEditTemplate = (template) => {
    setFieldsTemplate(template);
    handleOpenEmailDialog(template);
  };
  const handleSelectTemplate = (templateEmailId) => {
    setFieldsTemplate(templateEmailId);
    handleOpenUsersTable();
  };

  const [openDialogConfirmDeleteTemplate, setOpenDialogConfirmDeleteTemplate] = useState(false);
  const handleOpenDialogConfirmDeleteTemplate = (template) => {
    setSelectTemplateToDelete(template);
    setOpenDialogConfirmDeleteTemplate(true);
  };
  const handleCloseDialogConfirmDeleteTemplate = () => {
    setOpenDialogConfirmDeleteTemplate(false);
  };

  const handleDeleteTemplate = async () => {
    try {
      await Axios.delete(`/emails`, {
        params: {
          id: selectTemplateToDelete._id,
        },
      });

      handleCloseDialogConfirmDeleteTemplate();
      window.location.reload();
    } catch (error) {
      formatHandleError({
        setSnackbar,
        defaultMessage: 'Ocorreu algum erro ao excluir o template',
        error,
      });
    }
  };

  return (
    <Box marginTop={4}>
      <SimpleBackdrop loading={loadingTemplatesEmailsList} absolutePosition />
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center" width="8%">
                ID
              </TableCell>
              <TableCell width="20%">Template</TableCell>
              <TableCell width="35%">Assunto</TableCell>
              <TableCell align="center" width="10%">
                Visualizar
              </TableCell>
              <TableCell align="center" width="10%">
                Editar
              </TableCell>
              <TableCell align="center" width="12%">
                Destinatários
              </TableCell>
              <TableCell align="center" width="5%">
                <DeleteForeverIcon className={classes.deleteTableHead} />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!loadingTemplatesEmailsList || templatesEmailsList.length > 0 ? (
              templatesEmailsList.map((template) => (
                <TableRow key={template.templateEmailId}>
                  <TableCell align="center">{template.templateEmailId}</TableCell>
                  <TableCell>{template.name}</TableCell>
                  <TableCell>{template.matter}</TableCell>
                  <TableCell align="center">
                    <Button
                      variant="outlined"
                      onClick={() => {
                        const htmlText = base64ToHtml(template.htmlMessage);
                        const blob = new Blob([htmlText], { type: 'text/html' });
                        const blobUrl = URL.createObjectURL(blob);
                        window.open(blobUrl);
                      }}
                    >
                      Visualizar
                    </Button>
                  </TableCell>
                  <TableCell align="center">
                    <Button variant="outlined" onClick={() => handleEditTemplate(template)}>
                      Editar
                    </Button>
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      variant="outlined"
                      onClick={() => handleSelectTemplate(template.templateEmailId)}
                    >
                      Selecionar
                    </Button>
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      onClick={() => handleOpenDialogConfirmDeleteTemplate(template)}
                      className={classes.delete}
                    >
                      <DeleteForeverIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6}>
                  <Grid container justifyContent="center" alignItems="center">
                    <Grid item>
                      <Typography>
                        {loadingTemplatesEmailsList ? 'Carregando' : 'Nenhum template para exibir'}
                      </Typography>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={7}>
                <CustomPagination
                  page={page}
                  total={totalTemplatesEmails}
                  limit={limit}
                  handleChangePage={handleChangePage}
                />
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </Paper>
      <FabGroupButtons fabButtons={fabButtons} />
      {openDialogConfirmDeleteTemplate ? (
        <SimpleDialog
          openDialog={openDialogConfirmDeleteTemplate}
          handleClose={handleCloseDialogConfirmDeleteTemplate}
          dialogTitle="Excluir Template"
          dialogText="Confirma a exclusão do template? (A ação não poderá ser desfeita)"
          cancelButtonText="Cancelar"
          confirmButtonText="Confirmar"
          handleCancelButton={handleCloseDialogConfirmDeleteTemplate}
          handleConfirmButton={handleDeleteTemplate}
        />
      ) : null}
      {snackbar.open && (
        <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
}

export default TemplatesTable;
